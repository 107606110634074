$(document).ready(function(){

    // Click to open drop down

    $('.c-dropdown').each(function(){
        var dropdown = $(this);
        var drop = dropdown.find('.c-dropdown__drop');
        drop.click(function(){
            if (dropdown.hasClass('c-dropdown--show')){
                dropdown.removeClass('c-dropdown--show');
            } else {
                $('.c-dropdown').removeClass('c-dropdown--show');
                dropdown.addClass('c-dropdown--show');
            }
        });
    });

    // Click anywhere to close drop down

    $(document).on('click', function(event) {
        if (!$(event.target).closest('.c-dropdown').length) {
            $('.c-dropdown').removeClass('c-dropdown--show');
        }
    });

});