$(document).ready(function(){
    $('.s-testimony__carousel').owlCarousel({
        margin: 35,
        loop: true,
        nav: false,
        dots: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            992: {
                items: 2,
            },
            1400: {
                items: 3,
            }
        }
    });
});