$(document).ready(function(){

    $('.c-tab').each(function(){
        var tab = $(this);
        var title = tab.find('.c-tab__title');
        title.click(function(){
            if (tab.hasClass('c-tab--show')){
                tab.removeClass('c-tab--show');
            } else {
                $('.c-tab').removeClass('c-tab--show');
                tab.addClass('c-tab--show');
            }
        });
    });

    // <div class="c-table">
    //   <div class="c-table__scroll">Scrollable Table</div>
    //     <div class="table-responsive">
    //       <table class="table">
    //       </table>
    //    </div>
    // </div>

    $('.c-tab__content').each(function(){
        var content = $(this);
        content.find('table').each(function(){
            var table = $(this);
            table.wrap('<div class="table-responsive"></div>');
            $('<div class="c-table__scroll">Scrollable Table</div>').insertBefore(table);
            table.parent().wrap('<div class="c-table">');
            table.addClass('table');
        });
    });

});